import { BrowserRouter } from "react-router-dom";
import About from "./pages/About";
import Work from "./pages/Work";
import Contact from "./pages/Contact";
import Header from "./components/Header";
import Hero from "./pages/Hero";

import { useRef, useState } from "react";
import Loading from "./components/Loading/Loading";

import ScrollToTop from "react-scroll-to-top";
import logo from "./images/logo.png";

function App() {
  const circleRef = useRef(null);
  const mediaQuery = window.matchMedia("(max-width: 768px)");

  const [loading, setLoading] = useState(true);

  setTimeout(() => {
    setLoading(false);
  }, 2500);

  const movemove = (e) => {
    let x = e.clientX;
    let y = e.clientY;
    circleRef.current.style.display = "block";
    circleRef.current.style.top = y + -17 + "px";
    circleRef.current.style.left = x + -17 + "px";
    circleRef.current.style.zIndex = "999";

    if (mediaQuery.matches) {
      // Then trigger an alert
      circleRef.current.style.display = "none";
      // alert("Media Query Matched!");
    }

    if (e.target.innerText === "work together.") {
      circleRef.current.style.opacity = ".2";
      circleRef.current.style.cursor = "pointer";
    } else {
      circleRef.current.style.opacity = "1";
    }

    if (
      e.target.className === "about__item" ||
      e.target.className === "about__img-cursor" ||
      e.target.className === "about__name-title" ||
      e.target.className === "about__name-experience" ||
      e.target.className === "about__info" ||
      e.target.className === "info" ||
      e.target.id === "cart__section-test"
    ) {
      circleRef.current.style.opacity = "1";
      circleRef.current.style.zIndex = "1";
    }
  };

  const moveout = () => {
    circleRef.current.style.display = "none";
  };

  return (
    <div className="App">
      <BrowserRouter>
        <div className="App" onMouseMove={movemove} onMouseLeave={moveout}>
          <div className="cursor" ref={circleRef}></div>

          {loading ? (
            <Loading />
          ) : (
            <>
              <Header />
              <Hero />
              <About />
              <Work />
              <Contact />{" "}
              <ScrollToTop
                smooth
                // color="red"
                // backgroundColor='red'
                // viewBox=" 0 24 24"
                component={
                  <img
                    src={logo}
                    alt="Logo"
                    style={{
                      width: "40px",
                      backgroundColor: "#242424",
                      borderRadius: "10%",
                      cursor: "alias",
                    }}
                  />
                }
              />
            </>
          )}
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
