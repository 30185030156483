import React, { useEffect, useRef } from "react";
import emailjs from "@emailjs/browser";
import { Container, Row, Col } from "reactstrap";
// styles
import '../styles/contact.scss'

import toast from "react-hot-toast";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        `${process.env.REACT_APP_SERVICE_ID_API}`,
        `${process.env.REACT_APP_TEMPLATE_ID_API}`,
        form.current,
        `${process.env.REACT_APP_PUBLIC_KEY_API}`
      )
      .then(
        (result) => {
          console.log(result.text);
          form.current.reset();

          toast.success("Successfully sent!", {
            style: {
              borderRadius: "10px",
              background: "#333",
              color: "#fff",
            },
          });
        },
        (error) => {
          toast.error("Something went wrong", {
            style: {
              borderRadius: "10px",
              background: "#333",
              color: "#fff",
            },
          });
        }
      );
  };

  const firstRef = useRef(null);
  const secondRef = useRef(null);

  useEffect(() => {
    const el1 = firstRef.current;
    const el2 = secondRef.current;

    let tl1 = gsap.timeline({
      scrollTrigger: {
        trigger: ".contact-section",
        start: "-80%",
        end: "30%",
        scrub: 1,
        markers: false,
      },
    });

    let tl2 = gsap.timeline({
      scrollTrigger: {
        trigger: ".contact-section",
        start: "-30%",
        end: "-2%",
        scrub: 1,
        markers: false,
      },
    });

    tl1.fromTo(
      el1,
      { y: -200, transition: ".3s",color:'var(--primary-color)' },
      { y: 80, transition: ".5s",color:'#fff' }
    );

    tl2.fromTo(
      el2,
      { y: 300,x:900, transition: ".3s", scale: 0},
      { y: 0,x:0, transition: ".5s", scale: 1 }
    );
  }, []);

  return (
    <section className="contact-section" id="contact">
      <Container className="container-title">
        <Row>
          <Col lg="12">
            <h3 ref={firstRef}>Contact</h3>
          </Col>
        </Row>
      </Container>
      <div ref={secondRef} className="w-100">
        <Container className="container-contact">
          <Row>
            <form ref={form} onSubmit={sendEmail}>
              <Col lg="12" className="form__line">
                <label>Name</label>
                <input type="text" name="user_name" required />
              </Col>

              <Col lg="12" className="form__line">
                <label>Email</label>
                <input type="email" name="user_email" required />
              </Col>
              <Col lg="12" className="form__line">
                <label>Message</label>
                <textarea name="message" rows="5" required />
              </Col>

              <Col lg="12">
                <button type="submit" className="btn submit__btn">
                  Send
                </button>
              </Col>
            </form>
          </Row>
        </Container>
      </div>
    </section>
  );
};

export default Contact;
