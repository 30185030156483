import Spline from "@splinetool/react-spline";
import React, {useRef, useState } from "react";
import { Container } from "reactstrap";
import toast from "react-hot-toast";

// svg
import InstagramLogo from "../assets/SVG/Instagram-Logo.svg";
import GiHubLogo from "../assets/SVG/GitHub-Logo.svg";
// styles
import '../styles/hero.scss'

// main
import CV from "../components/CV/Tato-CV.pdf";
import Loading from "../components/Loading/Loading";

// animation
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const Hero = () => {
  const [loading, setLoading] = useState(true);

  const downloaded = () => {
    toast.success("CV has been downloaded");
  };

  const mainTitleRef = useRef(null);


  
  return (
    <section className="hero-section">
      {loading && <Loading />}
      <Container className="container-hero">
        <div className="hero-info">
        <div className="soc__media">
            <div className="soc__instagram">
              <a href="https://www.instagram.com/tato.dznelashvili/" target="_blank" rel="noopener noreferrer">
                <img src={InstagramLogo} alt="instagram" />
              </a>
            </div>
            <div className="soc__github">
              <a href="https://github.com/Ctato1" target="_blank" rel="noopener noreferrer">
                <img src={GiHubLogo} alt="github" />
              </a>
            </div>
          </div>
          <h5 ref={mainTitleRef}>Tato Dznelashvili Portfolio</h5>
          <p>
            I'm a Front-End Developer in Georgia. I love healthy food, <br />{" "}
            gym, anime, and Denzel Washington's Movies
          </p>
          <a href={CV} download="CV" onClick={downloaded}>
            <button className="btn">Download CV</button>
          </a>
        </div>
        <div className="spline-section">
          <Spline
            onLoad={() => setLoading(false)}
            className="spline"
            scene="https://prod.spline.design/irVF3HI6ruxdiUli/scene.splinecode"
          />
        </div>
      </Container>
    </section>
  );
};

export default Hero;
