import wineLine from "../images/wine-line.png";
import movie from "../images/movie.png";
import recipe from "../images/recipe.png";
import restaurant from "../images/restaurant.png";
import weather from "../images/weather.png";

const workData = [
  {
    id: "01",
    title: "Wine-Line",
    imgUrl: wineLine,
    category: "e-commerce",
    shortDesc: `E-COMMERCE web store - Buy Wine`,
    website: `https://wine-line.pages.dev/home`,
  },
  {
    id: "02",
    title: "Movie",
    imgUrl: movie,
    category: "Movie",
    shortDesc: `Movie Website - Watch Trailer`,
    website: `https://movie-app-react.pages.dev/home`,
  },
  {
    id: "03",
    title: "Recipe",
    imgUrl: recipe,
    category: "Recipe",
    shortDesc: `Recipe Hub - Search your favorite food`,
    description: `The grapes are cultivated in protected 
        designations of origin`,
    website: `https://recipe-react-host.pages.dev/home`,
  },
  {
    id: "04",
    title: "Restaurant",
    imgUrl: restaurant,
    category: "e-commerce",
    shortDesc: `Food Delivery - Order food now`,
    website: `https://restaurant-a18.pages.dev/home`,
  },
  {
    id: "05",
    title: "Weather",
    imgUrl: weather,
    category: "weather",
    shortDesc: `Weather app - Check the weather`,
    website: `https://ctato1.github.io/weather-app/`,
  },
];

export default workData;
