import React, { useRef, useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
// styles
import '../styles/about.scss'

// testimonial
import aboutData from "../assets/data/about-data";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Testimonial from "../components/Testimonial/Testimonial";

gsap.registerPlugin(ScrollTrigger);

const About = () => {
  const firstRef = useRef(null);
  const secondRef = useRef(null);
  const thirdRef = useRef(null);

  // gsap
  useEffect(() => {
    const el1 = firstRef.current;
    const el2 = secondRef.current;
    const el3 = thirdRef.current;
    

    let tl1 = gsap.timeline({
      scrollTrigger: {
        trigger: ".about-section",
        start: "-80%",
        end: "30%",
        scrub: 1,markers: false,
      },
    });
    let tl2 = gsap.timeline({
      scrollTrigger: {
        trigger: ".about-section",
        start: "-30%",
        end: "5%",
        scrub: 1,
        
      },
    });

    tl1.fromTo(
      el1,
      { y: -300, transition: ".3s" ,color:'var(--primary-color)'},
      { y: 80, transition: ".5s" ,color:'#fff'}
    );
    tl2.fromTo(
      el2,
      { y: 250, transition: ".3s",scale:0.4, },
      { y: 0, transition: ".5s" ,scale:1,}
    );
    tl2.fromTo(
      el3,
      { y: 250, transition: ".3s",scale:0.4, },
      { y: 0, transition: ".5s" ,scale:1,}
    );
  }, []);

  return (
    <section className="about-section" id="about">
      <Container className="container-title">
        <Row>
          <Col lg="12">
            <h3 ref={firstRef}>About</h3>
          </Col>
        </Row>
      </Container>
      <Container className="container-about">
        <Row>
          <Col lg="12" md="12">
            <div className="main__about" ref={secondRef}>
              <p>
                My name is Tato Dznelashvili. I’m a frontend software engineer &
                freelance web developer in Georgia. I'm passionate about music,
                technology and helathy life
              </p>
              <p>
                Whether you're a business owner looking to get started on web
                development project with a freelance web developer, a developer
                looking to collaborate on something cool, or just wanting to say
                hi, shoot me a message and let's{" "}
                <span className="work__together">
                  {" "}
                  <a href="#contact">work together.</a>{" "}
                </span>
              </p>
            </div>
          </Col>
        </Row>
      </Container>
      <Container className="container-slider mt-3">
        <Row>
          <div ref={thirdRef}>
            <Testimonial aboutData={aboutData} />
          </div>
        </Row>
      </Container>
    </section>
  );
};

export default About;
