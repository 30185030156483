import React, { useEffect, useRef } from "react";
import { Container, Row, Col } from "reactstrap";
// styles
import "../styles/work.scss";

import workData from "../assets/data/work-data";
import WorkCard from "../components/UI/WorkCard";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const Work = () => {
  const firstRef = useRef(null);

  useEffect(() => {
    const el1 = firstRef.current;

    let tl1 = gsap.timeline({
      scrollTrigger: {
        trigger: ".work-section",
        start: "-50%",
        end: "5%",

        scrub: 1,
      },
    });

    tl1.fromTo(
      el1,
      { y: -200, transition: ".3s", color: "var(--primary-color)" },
      { y: 80, transition: ".5s", color: "#fff" }
    );
  }, []);

  return (
    <section className="work-section" id="work">
      <Container className="container-title mt-5 mb-5">
        <Row>
          <Col lg="12">
            <h3 ref={firstRef}>Work</h3>
          </Col>
        </Row>
      </Container>

      <div className="main">
        <Container className="container-work mt-5">
          {workData.map((item, index) => (
            <Row>
              <WorkCard item={item} key={index} />
            </Row>
          ))}
        </Container>
      </div>
    </section>
  );
};

export default Work;
