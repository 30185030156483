import React, { useEffect, useRef } from "react";
import { Container} from "reactstrap";

import { useNavigate } from "react-router-dom";
// styles
import '../styles/header.scss'




const Header = ({ timeline, ease }) => {
  const nav__links = [
    {
      title: "About",
      path: "#about",
    },
    {
      title: "Work",
      path: "#work",
    },
    {
      title: "Contact",
      path: "#contact",
    },
  ];

  const barRef = useRef(null);
  const navLinkRef = useRef(null);
  const blackRef = useRef(null);
  const headerRef = useRef(null);

  const toggleBar = () => {
    barRef.current.classList.toggle("active");
    navLinkRef.current.classList.toggle("active__header-links");
    blackRef.current.classList.toggle("active__black");
  };

  const stickyHeaderFunc = () => {
    window.addEventListener("scroll", () => {
      if (
        document.body.scrollTop > 80 ||
        document.documentElement.scrollTop > 80
      ) {
        headerRef.current.classList.add("sticky__header");
      } else {
        headerRef.current.classList.remove("sticky__header");
      }
    });
  };

  useEffect(() => {
    stickyHeaderFunc();

    return () => window.removeEventListener("scroll", stickyHeaderFunc);
  }, []);


  const navigate = useNavigate();


  return (
    <section className="header__section" ref={headerRef}>
      <div
        ref={blackRef}
        className="black-everything"
        onClick={toggleBar}
      ></div>
      <Container className="header">
        <h5
          className="header-title"
          onClick={() => {
            window.scrollTo(0, 0);
            navigate('/')
          }}

        >
          Tato Dz<span>nelashvili</span>
        </h5>
        <div className="nav-res">
          <div className="header-links" ref={navLinkRef} onClick={toggleBar}>
            {nav__links.map((nav, index) => (
              <a href={nav.path} key={index}>{nav.title}</a>
            ))}
          </div>
        </div>

        <div className="bar-links" ref={barRef} onClick={toggleBar}>
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
        </div>
      </Container>
    </section>
  );
};

export default Header;
