import React from "react";
import logo from "../../images/logo.png";

import "./loading.css";

const Loading = () => {
  return (
    <section className="loading-section">
      <div className="line-img">
        <img src={logo} alt="Logo"/>
      </div>
    </section>
  );
};

export default Loading;
