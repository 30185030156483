import React from "react";
import { Col} from "reactstrap";

// styles
import "./work-card.scss";
//gsap
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const WorkCard = ({ item }) => {

  return (
    <Col
      lg="12"
      md="12"
      sm="12"
      xs="12"
      className="mt-2 mb-2 d-flex align-items-center justify-content-center"
    >
      <div className={`product__item`} key={item.id} >
        <div className="product__img">
          <img src={item.imgUrl} alt="Product"/>
        </div>

        <div className="dark__theme">
          <div className="info p-2">
            {/* <i class="ri-close-line"></i> */}
            <div className="product__info p-2">
              <h3 className="product__name mb-3">{item.title}</h3>
              <span>Type: {item.category}</span>
            </div>
            <div className="product__card-bottom d-flex align-items-center justify-content-between p-2">
              <span className="product__short-desc">
                Description: {item.shortDesc}
              </span>
            </div>

            <a href={item.website} target="_blank" rel="noopener noreferrer">
              <button className="btn info__btn">Visit Website</button>
            </a>
          </div>
        </div>
      </div>
    </Col>
  );
};

export default WorkCard;
